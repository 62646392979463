import React from 'react';
import { Link } from 'react-router-dom';
<link rel="stylesheet" href="css/all.min.css"></link>

const DoctorDetailsArea = (props) => {

   const {dataDoctor, handleModalShow} = props;


   console.log("dataDoctor ",dataDoctor)

   return (
      <>
         <div className="doctor-details-area pt-115 pb-70">
         
            <div className="container">
           
               <div className="row">
                  <div className="col-xl-7 col-lg-8">
                     <article className="doctor-details-box">
                        <div className="section-title pos-rel mb-25">
                           <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/section/section-back-icon-sky.png"
                                 alt="" />
                           </div>
                           <div className="section-text pos-rel">
                              <h3 className="green-color text-up-case">introducing myself</h3>                  
                           </div>                           
                        </div>
                        <div className="service-details-text mb-40">
                        <div className="ser-fea-list fix">
                                 <h3></h3>
                                 <ul>                               
                                    <li>
                                       <i className="fas fa-check"></i>
                                       {dataDoctor.description}
                                    </li>                                                      
                                 </ul>
                              </div>
                        </div>
                        <div className="section-title pos-rel mb-25">
                           <div className="section-text pos-rel">
                              <h1>Services</h1>
                           </div>                           
                        </div>                        
                        <div className="service-details-feature fix mb-30">
                           <div className="ser-fea-box f-left">
                              <div className="ser-fea-icon f-left">
                                 <img src="img/services/ser-fea-icon-1.png" alt="" />
                              </div>
                              <div className="ser-fea-list fix">
                                 <h3></h3>
                                 <ul>
                                 {dataDoctor.services.map((service) => (
                                    <li key={service.id}>
                                       <i className="fas fa-check"></i>
                                       {service.name}
                                    </li>
                                 ))}                           
                                 </ul>
                              </div>
                           </div>
                          
                        </div>
                        <div className="section-title pos-rel mb-25">
                           <div className="section-text pos-rel">
                              <h1>Education</h1>
                           </div>                           
                        </div>
                        <div className="service-details-text mb-30">
                           {/* <p>{dataDoctor.short_description}</p> */}                         
                           <div className="ser-fea-list fix">
                                 <h3></h3>
                                 <ul>                               
                                    <li>
                                       <i className="fas fa-check"></i>
                                       {dataDoctor.education}
                                    </li>                                                      
                                 </ul>
                              </div>
                           
                        </div>
                        <div className="section-title pos-rel mb-25">
                           <div className="section-text pos-rel">
                              <h1>Languages</h1>
                           </div>                           
                        </div>
                        <div className="service-details-text mb-30">
                           {/* <p>{dataDoctor.short_description}</p> */}                          
                           <div className="ser-fea-list fix">
                                 <h3></h3>
                                 <ul>                               
                                    <li>
                                       <i className="fas fa-check"></i>
                                       {dataDoctor.languages}
                                    </li>                                                      
                                 </ul>
                              </div>
                        </div>
                        <div className="section-title pos-rel mb-25">
                           <div className="section-text pos-rel">
                              <h1>Doctor's Office</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>  
                        <div className="team-thumb">
                              <img src={`${dataDoctor.office_image}`} alt="Mi Imagen"  style={{ width: '270px', height: '320px', marginRight: '30px',  borderRadius: '10px',  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'}} /> 
                              <img src={`${dataDoctor.office_image_second}`} alt="Mi Imagen"  style={{ width: '270px', height: '320px', marginRight: '30px', borderRadius: '10px',  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }} />                              
                        </div>                                          
                     </article>
                  </div>
                  <div className="col-xl-5 col-lg-4">
                     <div className="service-widget mb-50">
                        <div className="team-wrapper team-box-2 team-box-3 text-center mb-30">
                           <div className="team-thumb">
                              <img src={`${dataDoctor.photo}`} alt="Mi Imagen"  style={{ width: '370px', height: '420px', borderRadius: '10px',  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'}} />
                             
                           </div>
                           <div className="team-member-info mt-35 mb-35">
                              <h3><Link to="#">{dataDoctor.user.name}</Link></h3>
                              <h6 className="f-500 text-up-case letter-spacing pink-color">{dataDoctor.title}</h6>
                           </div>
                           <div className="Make Appoinment">
                              <ul>             
                                    <button className="primary_btn green-bg-btn" onClick={() => handleModalShow()} type="button">
                                    Schedule your Appointment
                                    </button>

                              </ul>
                           </div>
                        </div>
                     </div>
                     {/* <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">Qualifications</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                              <li>
                                 <Link to="#">
                                    <div className="more-service-icon"><img src="img/services/more-ser-1.png" alt="" /></div>                                 
                                 </Link>
                              </li>
                           </ul>
                        </div>
                     </div> */}
               
                     {/* <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">Languages</h3>
                        </div>
                        <div className="more-service-title doctor-details-title">
                           <ul>
                              <li><a href="#">{dataDoctor.languages}</a></li>
                           </ul>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
         </div>

         
      </>
   );
};

export default DoctorDetailsArea;