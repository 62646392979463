import axios from "axios";
//import Auth from 'redux/constants/Auth';
//import { AUTH_TOKEN } from "redux/constants/Auth";

const URL_BASE =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_PROD;

const TOKEN_PAYLOAD_KEY = "authorization";
const ENTRY_ROUTE = "/";

class ApiService {
  _instance;

  constructor() {
    let path = "";
    if (typeof window !== "undefined") {
      path = window.location.pathname;
    }

    this._instance = axios.create({ baseURL: URL_BASE });
    this._instance.interceptors.request.use(
      function (config) {

        const jwtToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMzAwZTFiY2NiMGQwYjU2OTcxYzFiZGIzNjg3MzNhOTZiNGMzNGY4ZDdiYTc1ODY1MDRkOTY0YjM3MTY5MmQ5NjM0M2UzMzE4YjljYWM0MWIiLCJpYXQiOjE3MjM1NzM5NzIuNTQ0MTI5LCJuYmYiOjE3MjM1NzM5NzIuNTQ0MTMzLCJleHAiOjE3NTUxMDk5NzIuNTM5NDE1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ikAj4SwGkHtVZ16oPVxaeat_gR2JGd76YVVVJIqNFKy75U-5RQPgEDRGb4GQidoKzOigfezCldX7fmAW7xSczqji-xm8RzHgW_laoZRdjRYA1YCh_2XV49heIygP5l0pqE0SVUqIrsjKW3CJTOnltIQqMquByFi4NnDGvTpqWp6tPSbFtJbOfJvXxNMMB7BDsB8qtFh98BdDRp0zUNzcYh92X5kcqUbPA0K4m4RVQkPtW6vF1MHirM8a6P-mZA6XCkrj5DbTlNVukGbzX9wqd66EISfRcPJXUEehPn_MgPWTOD2b5BfyIx0yjVyfgr_sHxpQEp1SzYRZK-TcNxqHEpQ0DqlJffdwmkEfgHE8Xnyty_yoAMMjKc3q6hmXdNN0MPOvj9mQkuOaXGgVv18ajixsdRE5V7uD4QSf7rMPKAxbB6-LWaIN9gJXvvxHPgDAtKQZ25z912XZlSeERsA4MMWIboTWISA8UKBbR55KOmzQ1ZDXudoHFc283M4-vzu_pTxuPEgEi8-5HaoJXha-80VNnZm8ZvFJYKYD1hMX0w-U11uYatO4WVC93qutcvy--5Wvkc5J7K5EGC90iaiCKjvS59l6z21V_MIXdaq11bP5B5gVNkf0hd78YydAc9kDMdgnSinrGmOQ7nDM09OzCPLj5Ziq1xY5wFvs_TfdFGo';


        if (jwtToken) {
          config.headers = {
            ...config.headers,
            [TOKEN_PAYLOAD_KEY]: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
            
          };
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this._instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (error.response.status === 401 && path !== ENTRY_ROUTE) {
          localStorage.clear();
          //window.location.pathname = ENTRY_ROUTE;
        }
        return Promise.reject(error);
      }
    );
  }

  async signin(params) {
    return await this._instance.post("/login", params);
  }

  async getPatientsOne(id) {
    return await this._instance.get(`/patients/${id}`);
  }

  
  async getSpecialties() {
    return await this._instance.get("/specialties");
  }
  
  async getSubSpecialties() {
    return await this._instance.get("/subspecialties");
  }

  async getCities(selectType) {
    return await this._instance.get(`/get-all-cities?subspecialty_type=${selectType}`);
  }

  
  async postAppointments(params) {
    return await this._instance.post(`/appointments`,params);
  }

async postAppointments2(params) {
  return await this._instance.post(`/appointmentsaddappointment`,params);
  }


  async filterByDoctor(virtualCall, speciality, subspeciality, city,type) {

    
    try {
      const response = await this._instance.get("/filter-by-doctor", {
        params: {
          virtual_call: virtualCall,
          speciality: speciality,
          subspeciality: subspeciality,
          city: city,
          type:type
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error filtering by doctor:", error);
      throw error;
    }
  }

  async get2Doctors(id) {
    return await this._instance.get(`/doctors/${id}`);
  } 

  async getDoctors() {
    return await this._instance.get(`/doctors`);
  } 

  async getServices() {
    return await this._instance.get(`/services`);
  } 



  async getServicesfilter(params) {
    
    return await this._instance.get(`/services-filter?type=${params.type}&city=${params.city}`);
  } 



  async postFilterbyDoctorServic(ids) {

    return await this._instance.post(`/filter-by-doctor-service/${ids}`);
  } 


  async getDoctorsByServices(params) {
    return await this._instance.get(`doctors-by-services?city=${params.city}&service=${params.service}`);
  }
  
  

  async sendComments(params) {
    
    return await this._instance.post(`/sendComments`,params);
  } 


  async getAllCountries() {
    
    return await this._instance.get(`/getAllCountries`);
  } 


  
  async getCitiesByGeonameId(geonameId) {
    
    return await this._instance.get(`/getCitiesByGeonameId?geonameId=${geonameId}`);
  } 


  async resendVerificationEmail(params) {
    
    return await this._instance.get(`/resendVerificationEmail?email=${params}`);
  } 



  /*async filterDoctors(virtualCall, specialty, subspecialty, city) {
    const url = `/filter-by-doctor?virtual_call=${virtualCall}&speciality=${specialty}&subspeciality=${subspecialty}&city=${encodeURIComponent(city)}`;

    try {
      const response = await this._instance.get(url);
      return response.data;
    } catch (error) {
      console.error('Error en la llamada a la API:', error);
      throw error;
    }
  }*/
}

export default new ApiService();