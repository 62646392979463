import React, {useState,useEffect} from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';


const PaymentForm = (props) => {

 const {setStripeToken, stripeToken, formData, service, handleSubmit2, selectedOption,setTotal,total,spinner}=props;

console.log("service ", service);

 const [arrayServices, setArrayServices] = useState([]);
 

 const addToTotal = (amount) => {
   setTotal((prevTotal) => prevTotal + parseInt(amount));
 };

 useEffect(() => {
   const newArrayServices = [];
   const seenServices = new Set(); // Usamos un Set para evitar duplicados

   setTotal(0);

   if (selectedOption === 'client') {
     service.forEach((ser) => {
       ser.services_2.forEach((cost) => {
         addToTotal(cost.cost);

         // Usa el ID o una propiedad única para asegurar la unicidad
         if (!seenServices.has(cost.id)) {
           seenServices.add(cost.id);
           newArrayServices.push(cost);
         }
       });
     });
   } else {

     service.forEach((ser) => {
       addToTotal(ser.cost);

       if (!seenServices.has(ser.value)) {
         seenServices.add(ser.value);
         newArrayServices.push(ser);
       }
     });
   }

   setArrayServices(newArrayServices);

 }, [service]);


  const stripe = useStripe();
  const elements = useElements();

  const clearCardElement = () => {
    if (elements) {
      const cardElement = elements.getElement(CardElement);
      cardElement.clear();
    }
  };




  const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
  
      const cardElement = elements.getElement(CardElement);
  
      const { token, error } = await stripe.createToken(cardElement);

      if (error) {
        
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message || 'An unknown error occurred',
        });
      } else {
        
      

        handleSubmit2(elements,token.id);
      }
   
  };

  
  return (


    <>
      <div className="col-lg-6">
          <div className="your-order mb-30 ">
              <h3>Payment Information</h3>
              <form>
                    <CardElement/>
              </form>
          </div> 
      </div>


      <div className="col-lg-6">
          <div className="your-order mb-30 ">
                <h3>Your order</h3>

                <div className="your-order-table table-responsive">
                      <table>
                          <thead>
                            <tr>
                              <th className="product-name">Price</th>
                              <th className="product-total">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="cart_item">
                                <td className="product-name">
                                  Original cost
                                </td>
                                <td className="product-total">
                                    <span className="amount">{Object.keys(service).length === 0?("$0"):(`$${total} USD`)}</span>
                                </td>
                              </tr>
                                  <tr className="cart_item">
                                  <td className="product-name">
                                     Final cost
                                  </td>
                              <td className="product-total">
                                <span className="amount">{Object.keys(service).length === 0?("$0"):(`$${total} USD`)}</span>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                              <tr className="order-total">
                                    <th>Amount Paid</th>
                                    <td><strong><span className="amount">{Object.keys(service).length === 0?("$0"):(`$${total} USD`)}</span></strong>
                                    </td>
                              </tr>
                          </tfoot>                      
                      </table>
                </div>
      
                {arrayServices.map((objeto, index) => (
                  <p key={index}>{index+1} - {objeto.label}: {objeto.description}</p>
                ))}


            </div>
        </div>


       {spinner?(
                <button type="button" className="btn btn-success" onClick={handleSubmit} disabled>
                  <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  <span role="status"> Pay Now...</span>
                </button>
                
                
                ):(
                <button type="button" className="btn btn-success" onClick={handleSubmit} disabled={!stripe}>
                  Confirm and Request Appointment
                </button>
      )}


    </>    
  );
};

export default PaymentForm;
