import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../../hooks/useGlobalContext';

const HomeHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   const handleLoginClick = () => {
      // Redirigir al enlace de inicio de sesión
      window.location.href = "http://dev-codelines.space/login";
   };

   // Define las rutas de las imágenes de los logotipos
   const transparentLogo = "/img/logo/logo.png";
   const stickyLogo = "/img/logo/logo1.png";

   // Establece la imagen del logotipo en función de stickyMenu
   const logoImage = stickyMenu ? stickyLogo : transparentLogo;

   return (
      <>
         <header >
            <div className="top-bar d-none d-md-block">
               <div className="container">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-6 offset-xl-1 col-lg-6 offset-lg-1 col-md-7 offset-md-1">
                     </div>
                     <div className="col-xl-5 col-lg-5 col-md-4">
                        <div className="header-top-right-btn f-right">
                           {/*<Link to="/contact" className="primary_btn">Make Appointment</Link>*/}
                        </div>
                     </div>
                  </div>
               </div>
            </div>


             
            <div className={stickyMenu ? "sticky_menu header-menu-area header-padding transparrent-header sticky_navBar_bg" :
               "header-menu-area header-padding transparrent-header"}>
               <div className="container menu_wrapper custom-menu">
                  <div className="row align-items-center">
                     <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                        {/*<div className="logo logo-circle pos-rel">*/}
                       <div className="logo pos-rel">
                           <Link to="/"><img src={logoImage} alt="Logo" /></Link>
                        </div>
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                        <div className="header-right f-right">                           
                           <div className="header-lang f-right pos-rel d-none d-lg-block">
                           </div>
                        
                        </div>
                        <div className="header__menu header-menu-white f-right">
                           
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/">Home</Link> </li>
                                 {/*<li><Link to="/services">Services</Link> </li>                                 
                                 <li><Link to="/doctorsTwo">Doctors</Link> </li>*/}
                                 <li><a href="/">About Us</a> </li> {/* Enlace a HomeTwoAbout */}
                                 <li><a href="https://app.mtatrust.com/">Hearts for Healing Program</a></li>
                                 <li><a href="https://app.mtatrust.com/" onClick={handleLoginClick} >Log In</a></li>
                              </ul>
                           </nav>
                        
                        </div>

                        <div className="side-menu-icon d-lg-none text-end">
                           <button onClick={handleShow} className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars"></i> </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeHeader;