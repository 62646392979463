import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';

import CookieConsent from "react-cookie-consent"; 

const HomeTwoNavBar = () => {

   const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

   const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);



   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   const handleLoginClick = () => {
      // Redirigir al enlace de inicio de sesión
      window.location.href = "https://app.mtatrust.com/";
   };

   // Define las rutas de las imágenes de los logotipos
   const transparentLogo = "img/logo/logo.png";
   const stickyLogo = "img/logo/logo1.png";

   // Establece la imagen del logotipo en función de stickyMenu
   const logoImage = stickyMenu ? stickyLogo : transparentLogo;

   return (
      <>
         <header>
            {/* <!-- menu-area --> */}
            
            <div className={stickyMenu ? "sticky_menu header-menu-area header-padding transparrent-header sticky_navBar_bg" :
               "header-menu-area header-padding transparrent-header"}>
            {/*<div className={"header-menu-area header-padding sticky_navBar_bg"}>  */}
               <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-2 col-lg-2 col-md-3 col-4">
                     <div className="logo pos-rel">
                           <Link to="/"><img src={logoImage} alt="Logo" /></Link>
                        </div>
                     </div>
                     
                     
                     <div className="col-xl-10 col-lg-10 col-md-10 col-24">



                     
                     {isLargeScreen && (
                           <div className="header__menu header-menu-white">
                              <nav>
                              <ul>
                                 <li><a href="#">Home</a></li>
                                 <li><a href="/#about-section">About Us</a></li>
                                 <li><a href="/about">Hearts for Healing Program</a></li>
                                 <li style={{ float: isLargeScreen ? 'right' : 'none' }}>
                                    <a href="https://app.mtatrust.com/" onClick={handleLoginClick}>Log In</a>
                                 </li>
                              </ul>
                              </nav>
                           </div>
                        )}
                                       
                        
                        <div onClick={handleShow} className="side-menu-icon d-lg-none text-end">
                           <button className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars text-white"></i> 
                              </button>
                        </div>

                        <CookieConsent style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", color:"#7A7A7A"}}
                        //location="bottom"
                        //cookieName="myAwesomeCookieName2"
                        //style={{ background: "#2B373B" }}
                        //buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                        expires={150}
                        > <strong>
                           We use cookies to enhance your experience on our website. 
                           By continuing to use our website, you consent to the use of 
                           cookies in accordance with our Cookie Policy. You can manage your 
                           cookie settings by clicking "Cookie Preferences."{" "} </strong>
                           {/*<span style={{ fontSize: "8px" }}>This bit of text is smaller :O</span>*/}
                        </CookieConsent>
                        
                     </div>                  
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};


export default HomeTwoNavBar;